import { mapState } from 'vuex';
export default {
  name: '',
  components: {},
  props: {},

  data() {
    return {};
  },

  computed: {
    activeMenu() {
      const route = this.$route;
      return route.name;
    },

    ...mapState({
      irMenuData: state => state.irMenuData,
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},

  created() {},

  mounted() {},

  methods: {
    flushCom: function (items) {
      console.log(items.value);

      if (items.value == 'stock') {
        this.$router.go(0);
      }
    }
  }
};